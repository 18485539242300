import React, {createContext, useContext, useEffect, useState} from "react";
import HelpTimeOut, {timeout} from "../../../utility/Helper";
import ReportRepository from "../../../repository/report/ReportRepository";
import api from "js-cookie";

const JourneyContext = createContext();

export const JourneyProvider = ({children}) => {
    const [loading, setLoading] = useState(false)
    const [isModalShow, setIsModalShow] = useState(false)
    const reportRepository = new ReportRepository();
    const [sliderItems, setSliderItems] = useState([]);

    const generateDateLabel = (offset) => {
        setLoading(true)
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const date = new Date();
        date.setDate(date.getDate() + offset);
        const dayName = days[date.getDay()];
        const dayNumber = date.getDate();
        const text = `${dayName} ${dayNumber}`;
        return {date: text, isActive: offset === 0, level: 1, dayOfMonth: dayNumber, dayName: dayName};
    };
    useEffect(() => {

        sendApiRequest()

    }, []);


    const onSliderItemClick = (item) => {
        setSliderItems(prevState => prevState.map((prev) => {
            if (prev.date === item.date) {
                return {...prev, isActive: true}
            } else {
                return {...prev, isActive: false}
            }
        }));


    }

    async function sendApiRequest() {
        setLoading(true);
        const response = await reportRepository.getAllJourney()

        const initialSliderItems = [
            generateDateLabel(0),
            generateDateLabel(-1),
            generateDateLabel(-2),
            generateDateLabel(-3),
            generateDateLabel(-4),
            generateDateLabel(-5),
            generateDateLabel(-6),
            generateDateLabel(-7),
        ];

        if (!response.success) {
            return;
        }
        if (response.data === []) {
            return;
        }

        initialSliderItems.forEach((sliderItem, index) => {
            response.data.forEach(journey => {
                const date = new Date(journey.date_time);
                if (sliderItem.dayOfMonth === date.getDate()) {
                    sliderItem.level = journey.level;
                }
            });

        })
        setLoading(false);

        setSliderItems(initialSliderItems);


    }


    const openModal = () => {
        setIsModalShow(true);
    }
    const closeModal = () => {
        setIsModalShow(false);
    }
    return (
        <JourneyContext.Provider value={{
            onSliderItemClick,
            loading,
            setLoading,
            closeModal,
            isModalShow,
            openModal,

            sliderItems,

        }}>
            {children}
        </JourneyContext.Provider>
    );
}

export function JourneyUseContext() {
    return useContext(JourneyContext);
}
