import {createBrowserRouter, Navigate} from "react-router-dom";
import HomeScreen from "../views/pages/home/HomeScreen";
import HomeProvider from "../views/pages/home/HomeContext";
import MainContext, {MainProvider} from "../views/pages/main/MainContext";
import MainScreen from "../views/pages/main/MainScreen";
import ProfileScreen from "../views/pages/profile/ProfileScreen";
import {ProfileProvider} from "../views/pages/profile/ProfileContext";
import MoodScreen from "../views/pages/mood/MoodScreen";
import {MoodProvider} from "../views/pages/mood/MoodContext";
import JourneyScreen from "../views/pages/journy/JourneyScreen";
import PodcastProvider from "../views/pages/podcast/PodcastContext";
import PodcastScreen from "../views/pages/podcast/PodcastScreen";

import AiScreen from "../views/pages/ai/AiScreen";
import Analyze from "../views/pages/analyze/Analyza";
import AiProvider from "../views/pages/ai/AiContext";
import EmotionSection from "../views/pages/feels/EmotionScreen";
import BehaviorScreen from "../views/pages/behavior/BehaviorScreen";

import BreathProvider from "../views/pages/breath/BreathContext";
import Breath from "../views/pages/breath/Breath";
import ResultScreen from "../views/pages/result/ResultScreen";
import DepressionScreen from "../views/pages/depression/DepressionScreen";
import ThoughtScreen from "../views/pages/thoughts/ThoughtScreen";
import AnalyzeProvider from "../views/pages/analyze/AnalyzeContext";
import {BehaviorProvider} from "../views/pages/behavior/BehaviorContext";
import {EmotionProvider} from "../views/pages/feels/EmotionContext";
import {DepressionProvider} from "../views/pages/depression/DepressionContext";
import {ResultProvider} from "../views/pages/result/ResultContext";
import {PlayPodcastProvider} from "../views/pages/podcast/play/PlayPodcastContext";
import PlayPodcastScreen from "../views/pages/podcast/play/PlayPodcastScreen";
import AnxietyScreen from "../views/pages/anxiety/AnxietyScreen";
import {AnxietyProvider} from "../views/pages/anxiety/AnxietyContext";
import {JourneyProvider} from "../views/pages/journy/JourneyContext";
import AiResult from "../views/pages/ai/AiResult";
import {AiResultProvider} from '../views/pages/ai/AiResultcontext'
import ToughtResult from '../views/pages/thoughts/ThoughtResult'
import { ThoughtResultProvider } from "../views/pages/thoughts/ThoughtResultContext";
class AppRouter {
    static router = createBrowserRouter([
        {
            element: (
                <MainProvider>
                    <MainScreen/>
                </MainProvider>
            ),
            path: "/",
            children: [
                {
                    path: "/",
                    element: <Navigate to="/home" replace/>,
                },

                {
                    path: "home",
                    element: (

                        <HomeProvider>
                            <HomeScreen/>
                        </HomeProvider>
                    ),
                    children: [
                        {
                            path: "login",
                        },
                        {
                            path: "signup",
                        },
                        {
                            path: "reminders",
                        },
                        {
                            path: "new-reminder",
                        },
                    ],
                },
                {
                    path: "journey",
                    element: (
                        <JourneyProvider>
                            <JourneyScreen/>
                        </JourneyProvider>
                    ),
                },

                {
                    path: "profile",
                    element: (
                        <ProfileProvider>
                            <ProfileScreen/>
                        </ProfileProvider>
                    ),
                },
                {
                    path: "mood",
                    element: (
                        <MoodProvider>
                            <MoodScreen/>
                        </MoodProvider>
                    ),
                },

                {
                    path: "mood/question",
                    element: (
                        <EmotionProvider>
                            <EmotionSection/>
                        </EmotionProvider>
                    ),
                },
                {
                    path: "breath",
                    element: (
                        <BreathProvider>
                            <Breath/>
                        </BreathProvider>
                    ),
                },
                {
                    path: "ai",
                    element: (
                        <AiProvider>
                            <AiScreen/>
                        </AiProvider>
                    ),
                },
                {
                    path: "ai/result",
                    element: (
                  
                      <AiResultProvider>
                      <AiResult/>
                      </AiResultProvider>
                   
                    ),
                },
                {
                    path: "activity",
                    element: (<AnalyzeProvider>
                        <Analyze/>
                    </AnalyzeProvider>),
                },
                {
                    path: "home/podcast",
                    element: (
                        <PodcastProvider>
                            <PodcastScreen/>
                        </PodcastProvider>
                    ),
                },

                {
                    path: "/home/podcast/play",
                    element: (
                        <PlayPodcastProvider>
                            <PlayPodcastScreen/>
                        </PlayPodcastProvider>
                    ),
                },
                {
                    path: "behavior",
                    element: (
                        <BehaviorProvider>
                            <BehaviorScreen/>
                        </BehaviorProvider>
                    ),
                },
                {
                    path: "result",
                    element: <ResultProvider>
                        <ResultScreen/>
                    </ResultProvider>
                },
                {
                    path: "depression",
                    element: <DepressionProvider>
                        <DepressionScreen/>
                    </DepressionProvider>,
                },
                {
                    path: "anxiety",
                    element: (
                        <AnxietyProvider>
                            <AnxietyScreen/>
                        </AnxietyProvider>
                    ),
                },
                {
                    path: "thought",
                    element: <ThoughtScreen/>,
                },
                {
                    path: "thought/result",
                    element:<ThoughtResultProvider><ToughtResult/></ThoughtResultProvider> ,
                },
            ],
        },
    ]);
}

export default AppRouter;
