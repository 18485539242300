import axiosInstance from "../../services/remote/axios";
import HelpfinityTryCatch from "../../utility/Helper";

class AiResultRepository{
    async getResult() {
        // const questions = await require("../../data/mock/ai_refer.json");
        // await timeout(1000);
        //
        // return {success: true, message: "SUCCESS", data: questions};

        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.get("AIrefer/result")
            }
        )


    }
    async getThoughtResult() {
        // const questions = await require("../../data/mock/ai_refer.json");
        // await timeout(1000);
        //
        // return {success: true, message: "SUCCESS", data: questions};

        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.get("AIrefer/result")
            }
        )


    }
}

export default AiResultRepository