import Header from "../../components/section/Header";
import HelpButton from "../../components/atom/HelpButton";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {useLocation} from "react-router-dom";
import toast from "react-hot-toast";
import AiReferRepository from "../../../repository/ai/AiReferRepository";

const ThoughtScreen = () => {
    const [thought, setThought] = useState("");
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = useState(false);
    const location = useLocation();
    const repository = new AiReferRepository();

    const onBtnClick = async () => {
        setBtnLoading(true);
        const response = await repository.thoughts(thought);
        setBtnLoading(false);
        const state= location.state
        if (response.success) {
            navigate("/thought/result",{state: {state}})
           
        } else {
            toast.error(response.message);
        }


    };
    return (
        <div className="pt-88 pl-24 pr-24 left-0 pb-24 right-0 bg-cream_300 h-100 absolute">
            <div className="bg-white w-full h-100 rounded-3xl shadow-blur1 flex flex-col items-center relative">
                <Header
                    text={"Write your thoughts"}
                    iconeSec={"!bg-input"}
                    icon={"blueRight"}
                />

                <div className="p-24 w-full h-100">
          <textarea
              placeholder="Write your thought ..."
              className={`bg-blue_200 h-4/5 rounded-2xl p-16 text-10 font-medium text-DarkText3 w-full`}
              name="thought"
              onChange={(event) => {
                  setThought(event.target.value);
              }}
              id=""
          />
                </div>

                <div className="px-24 pb-24 w-full mt-auto">
                    <HelpButton title={"Done"} onClick={onBtnClick}/>
                </div>
            </div>
        </div>
    );
};

export default ThoughtScreen;
