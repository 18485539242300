import React, {createContext, useEffect, useState} from "react";
import AiResultRepository from '../../../repository/ai/AiResultRepository'

const ThoughtResultContext = createContext();

export const ThoughtResultProvider = ({children}) => {
    const aiRepo = new AiResultRepository()
    const [data, setdata] = useState("")
    const getResult = async () => {
        const response = await aiRepo.getThoughtResult()
        console.log("response")
        console.log(response)
        if (response.success) {
            setdata(response.data)
        } else {
            ///ERROR
        }
    }
    useEffect(() => {
        getResult();
    }, []);

    return (
        <ThoughtResultContext.Provider value={{data}}>
            {children}
        </ThoughtResultContext.Provider>
    )
}

export function ResultUseContext() {
    return React.useContext(ThoughtResultContext);
}
