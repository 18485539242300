import React from "react";
import Slider from "../atom/Slider";
import useAppNavigator from "../../../../../router/AppNavigator";
import toast from "react-hot-toast";
import Stargif from '../../../../../assets/photo/giphy-2 1.gif'
const QuickAccess = () => {
    const appNavigator = useAppNavigator();

    const boxShadowStyle = {
        boxShadow:
            "1px 2px 2px 0px rgba(66, 72, 116, 0.1), -1px -2px 2px 0px rgba(66, 72, 116, 0.1)",
    };

    const items = [
        {text: "Anxiety Relief", iconName: "groupMed", route: "/anxiety"},
        {text: "analyse thought", iconName: "groupCloud", route: "/thought"},
        {text: "Your Goals", iconName: "goal", route: "#"},
        {text: "Overcoming Depression", iconName: "groupCloud", route: "/depression"},
    ];

    const className =
        "!h-80  rounded-2xl  pb-4 pt-16 item-center !flex flex-col justify-evenly items-center text-center";

    const handleItemClick = (id, item) => {
        if (item.text === 'Your Goals') {
            toast.error("It will be added soon");
        }
        if (item.route && item.route !== "#") {
            appNavigator.navigate(item.route); // Navigate to the specified route if route exists and is not "#"
        }
    };

    return (
        <>
      <span className="mb-18 ml-18 text-DarkText3 text-12 font-semibold flex flex-row items-baseline w-auto custom-after">
        <span className="text-16 text-orange font-semibold mr-3">Ai</span>  Section 
      </span>
            <div className=" mb-11 -mr-24">
                <Slider
                    IconProp={true}
                    classOrigin={"mySwiper"}
                    slideNumber={3.7}
                    items={items}
                    className={className}
                    boxShadowStyle={boxShadowStyle}
                    onItemClick={handleItemClick} // Pass the route to handleItemClick
                />
            </div>
        </>
    );
};

export default QuickAccess;
