import React, {createContext, useEffect, useState} from "react";
import breath from "./Breath";
import toast from "react-hot-toast";
import {useLocation, useNavigate} from "react-router-dom";
import ReportRepository from "../../../repository/report/ReportRepository";

const BreathContext = createContext();
///Total Number Of Breathing Must Be Changed to 21

var totalNumberOfBreathing = 1;

const breathInTime = 4;
const breathOutTime = 6;
const breathHoldTime = 2;
const breathInTitle = "Breathe in";
const breathHoldTitle = "Hold your breath";
const breathOutTitle = "Breathe Out";
const BreathProvider = ({children}) => {
    const navigate = useNavigate();
    const [isSpinning, setIsSpinning] = useState(false);
    const [isBreathingFinished, setIsBreathingFinished] = useState(false);
    const [breathingStringTitle, setBreathingStringTitle] = useState(breathInTitle);
    const [breathState, setBreathState] = useState(0);
    const [numberOfBreathing, setNumberOfBreathing] = useState(0);
    const [timer, setTimer] = useState(0);
    const [countDownTimer, setCountDownTimer] = useState(null);
    const [breathBtnTitle, setBreathBtnTitle] = useState("");
    const [btnLoading, setBtnLoading] = useState(false)
    const reportRepository = new ReportRepository();
    //if number Reach 7 we're done
    /*
     * Breath State 0 -> Not Started Yet
     * Breath State 1 -> Breath In
     * Breath State 2 -> Hold
     * Breath State 3 -> Breath Out
     * Breath State 4 -> Finished
     * */

    ///Changing Spinning or Not Depend On Spinner State
    function timeout(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    useEffect(() => {
        switch (breathState) {
            ///Starting Stage
            case 0:
                setTimer(0);
                setIsSpinning(false);
                setBreathingStringTitle("Start");
                setBreathBtnTitle("Start Breathing");

                break;
            case 1:
                ///Breath In
                console.log("BREATH IN CALLLL");
                setTimer(breathInTime);
                setIsSpinning(true);
                setBreathingStringTitle(breathInTitle);

                setCountDownTimer(breathInTime);
                setBreathBtnTitle("Breathing...");

                // setBreathTimerAndChangeState();

                break;
            case 2:
                ///Breath Hold
                setBreathingStringTitle(breathHoldTitle);
                setCountDownTimer(breathHoldTime);

                setTimer(breathHoldTime);

                setIsSpinning(false);
                setBreathBtnTitle("Breathing...");
                break;

            case 3:
                ///BreathOut
                setTimer(breathOutTime);
                setBreathingStringTitle(breathOutTitle);
                setCountDownTimer(breathOutTime);
                setBreathBtnTitle("Breathing...");
                setIsSpinning(true);

                break;
            case 4:
                ///Finish
                // setTimer(0);
                setIsSpinning(false);
                setBreathingStringTitle("FINISH");
                // setBreathBtnTitle("Breathing...");
                setIsBreathingFinished(true);
                setBreathBtnTitle("Done");
                setCountDownTimer(null);
                break;

            default:
                setBreathingStringTitle(breathInTitle);
                setTimer(0);
                setIsSpinning(false);
        }
    }, [breathState]);

    useEffect(() => {
        const timeInMs = timer * 1000;
        timeout(timeInMs).then((r) => {
            if (breathState < 3 && breathState !== 0) {
                setBreathState((prevState) => prevState + 1);

            } else if (breathState === 3) {
                setBreathState(1);
                setNumberOfBreathing((prevState) => prevState + 1);
            }
        });
    }, [timer]);

    useEffect(() => {
        if (!countDownTimer) return;

        const intervalId = setInterval(() => {
            setCountDownTimer((prevState) => prevState - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [countDownTimer]);

    useEffect(() => {
        if (numberOfBreathing === totalNumberOfBreathing) {
            // setNumberOfBreathing(0);
            setBreathState(4);
        }
    }, [numberOfBreathing]);

    const onBreathBtnClick = async (state) => {
        console.log("CLICK ON BTN",isBreathingFinished);
        if (!isBreathingFinished) {
            const totalNumbers = Math.round(((state.duration * 60) / 12));
            totalNumberOfBreathing = totalNumbers;
            setBreathState(1);
            return;
        }

        setBtnLoading(true);
        const response = await reportRepository.setUserBreath();
        setBtnLoading(false);
        if (!response.success) {
            toast.error("Try Again.");
            return;
        }

        if (state === "RDCalm") {
            navigate("/thought", {state: "RDHome"});
        } else if (state === "RDJOUR") {
            navigate("/journey");
                
        } else {
            navigate("/home");
            toast.success("You did it . Start Next Step..");
        }
    };

    return (
        <BreathContext.Provider
            value={{
                countDownTimer,
                onBreathBtnClick,
                isSpinning,
                breathingStringTitle,
                breathState,
                breathBtnTitle,
                numberOfBreathing,
                totalNumberOfBreathing,
                btnLoading
            }}>
            {children}
        </BreathContext.Provider>
    );
};

function BreathUseStateContext() {
    return React.useContext(BreathContext);
}

export {BreathUseStateContext};

export default BreathProvider;
