import axiosInstance from "../../services/remote/axios";
import HelpfinityTryCatch, {timeout} from "../../utility/Helper";

class ReportRepository {

    async getAnalyzeReport() {
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.get("analyze/full")
            }
        )
    }

    async setUserBreath() {
        console.log("BREATH CALL");

        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.post('journey/breath');
            }
        )
    }

    async getAllJourney() {

        return await HelpfinityTryCatch(function () {
            return axiosInstance.get(`journey/journey`)
        })
    }

    async getDepressionAnswers() {

        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.get("emotion/depression")
            }
        )
    }

    async getAnxietyAnswers() {
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.get("AIrefer/answers")
            }
        )
    }

    async sendDepressionAnswer(answers) {
        await timeout(1000);
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.post("emotion/depression", answers)
            }
        )
    }

    async sendAnxietyAnswer(answers) {
        await timeout(1000);
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.post("emotion/anxitey", answers)
            }
        )
    }


}

export default ReportRepository;