import React, {createContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import PodcastRepository from "../../../../repository/podcast/PodcastRepository";

const PlayPodcastContext = createContext();


export const PlayPodcastProvider = ({children}) => {
    const podcastRepo = new PodcastRepository();
    const [pageLoading, setPageLoading] = useState(true)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const podcastId = searchParams.get('id');
    const [selectedPodcast, setSelectedPodcast] = useState({})
    const onInitialState = async () => {
        console.log("ID ", podcastId);
        setPageLoading(true);
        const response = await podcastRepo.podcastsItem(podcastId);
        setPageLoading(false);
        // if(response.success){
        //
        // }
        setSelectedPodcast(response.data);


    }
    useEffect(() => {
        console.log("INIT STATE..................");
        onInitialState()
    }, []);

    async function onPodcastEnded() {
        const response = await podcastRepo.listenToPodcastFinish();

    }


    return (
        <PlayPodcastContext.Provider value={{pageLoading, selectedPodcast, onPodcastEnded}}>
            {children}
        </PlayPodcastContext.Provider>
    );

}

export function PlayPodcastUseStateContext() {
    return React.useContext(PlayPodcastContext);
}

