import axiosInstance from "../../services/remote/axios";
import HelpfinityTryCatch, {timeout} from "../../utility/Helper";
import podcasts from "../../data/mock/podcast.json";

class PodcastRepository {
    async categories() {
        return await HelpfinityTryCatch(function () {
            return axiosInstance.get(`podcast/cats`)
        })

    }

    async podcastsList() {
        return await HelpfinityTryCatch(function () {
            return axiosInstance.get(`podcast/pods`)
        })

    }

    async podcastFullApi({category, searchQuery}) {
        try {
            const response = await axiosInstance.get(`podcast/full-pods?category=${category}&search=${searchQuery}`);


        } catch (e) {

        }
    }

    async podcastsItem(id) {
        return await HelpfinityTryCatch(function () {
            return axiosInstance.get(`podcast/pod/${id}`)

        })

    }

    async listenToPodcastFinish() {
        return await HelpfinityTryCatch(function () {
            return axiosInstance.get(`podcast/pod-done`)

        })

    }

}

export default PodcastRepository;